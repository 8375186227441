import request from "@/utils/request";
import store from "@/store";

export function getDuesTransactionList(data) {
  let url = "dues-transaction/get";

  if (store.getters.g_role_type === "STUDENT") {
    url = "dues-transaction/my-bill";
  }

  return request({
    method: "post",
    url: url,
    data: data
  });
}

export function getDuesTransactionDetail(data) {
  return request({
    method: "post",
    url: "dues-transaction/bill",
    data: data
  });
}

export function payDuesTransaction(data) {
  return request({
    method: "post",
    url: "dues-transaction/pay",
    data: data
  });
}

export function getDuesTransactionHistory(data) {
  let url = "dues-transaction/history";

  if (store.getters.g_role_type === "STUDENT") {
    url = "dues-transaction/my-history";
  }

  return request({
    method: "post",
    url: url,
    data: data
  });
}

export function getDuesMasterList(data) {
  return request({
    method: "post",
    url: "dues/get",
    data: data
  });
}

export function createDuesMaster(data) {
  return request({
    method: "post",
    url: "dues/add",
    data: data
  });
}

export function editDuesMaster(data) {
  return request({
    method: "post",
    url: "dues/edit",
    data: data
  });
}

export function detailDuesMaster(data) {
  return request({
    method: "post",
    url: "dues/detail",
    data: data
  });
}

export function setStatusDuesMaster(data) {
  return request({
    method: "post",
    url: "dues/non-active",
    data: data
  });
}

export function getDuesList(data) {
  return request({
    method: "post",
    url: "dues-payer/get",
    data: data
  });
}

export function createDues(data) {
  return request({
    method: "post",
    url: "dues-payer/add",
    data: data
  });
}

export function editDues(data) {
  return request({
    method: "post",
    url: "dues-payer/edit",
    data: data
  });
}

export function setStatusDues(data) {
  return request({
    method: "post",
    url: "dues-payer/non-active",
    data: data
  });
}

// ----------------------- Dropdown List
export function getDuesStatus() {
  return request({
    method: "post",
    url: "dues-dropdown/status"
  });
}

export function getDuesFrequency() {
  return request({
    method: "post",
    url: "dues-dropdown/frequency"
  });
}

export function getDuesCategory() {
  // level
  return request({
    method: "post",
    url: "dues-dropdown/category"
  });
}

export function getDuesPayer(data) {
  // level name
  return request({
    method: "post",
    url: "dues-dropdown/payer",
    data
  });
}

export function getDuesType(data) {
  return request({
    method: "post",
    url: "dues-dropdown/due",
    data
  });
}
