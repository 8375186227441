<template>
  <div>
    <v-sheet>
      <v-data-table
        :headers="tableHeaders"
        :items="historyList"
        :loading="loading"
        sort-by="created_at"
        sort-desc
        class="elevation-0"
      >
        <template v-slot:[`item.nominal`]="{ item }">
          Rp. {{ item.nominal | priceFormat }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
        <template v-slot:[`item.reference`]="{ item }">
          {{ item.reference ? item.reference : "-" }}
        </template>

        <template v-slot:[`item.margin_date`]="{ item }">
          {{ item.margin_date | dateFormat }}
        </template>
        <template v-slot:[`item.proof`]="{ item }">
          <v-img
            :src="item.proof"
            width="50"
            @click="showProof(item.proof)"
          ></v-img>
        </template>
      </v-data-table>
    </v-sheet>

    <v-dialog v-model="previewProof" width="400">
      <v-card class="text-center">
        <v-img :src="selectedProof"></v-img>
        <v-btn class="ma-4" text @click="previewProof = false">{{
          $i18n.t("app.close")
        }}</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("id");

import i18n from "@/i18n";
import { mapGetters } from "vuex";

import { getDuesTransactionHistory } from "@/api/admin/transactions";
import { priceFormat } from "@/utils/validate";

export default {
  props: {
    tab: Number,
    dueFilter: Number
  },
  filters: {
    priceFormat: value => {
      if (!value) return "-";
      return priceFormat(value);
    },
    dateFormat: value => {
      if (!value) return "0";
      return moment(value).format("DD MMM YYYY");
    }
  },
  data() {
    return {
      total: 0,
      length: 0,
      loading: false,
      selectedProof: null,
      historyList: [],
      previewProof: false,
      queryData: {
        person: this.$route.params.id,
        due: null
      },
      tableHeaders: [
        { text: i18n.t("dues.table.dues_type"), value: "name" },
        { text: i18n.t("dues.table.paid"), value: "nominal" },
        {
          text: i18n.t("dues.table.payment_date"),
          value: "created_at"
        },
        {
          text: i18n.t("dues.table.margin_date"),
          value: "margin_date"
        },
        {
          text: i18n.t("dues.table.reference"),
          value: "reference"
        },
        {
          text: i18n.t("dues.table.proof"),
          value: "proof",
          sortable: false
        }
      ]
    };
  },
  watch: {
    tab(newVal) {
      if (newVal === 1) {
        this.getDuesHistory();
      }
    },
    dueFilter(value) {
      this.queryData.due = value;
      this.getDuesHistory();
    }
  },
  computed: {
    ...mapGetters(["g_darkmode", "g_user"])
  },
  mounted() {
    this.getDuesHistory();
  },
  methods: {
    showProof(proof) {
      this.selectedProof = proof;
      this.previewProof = true;
    },
    getDuesHistory() {
      this.loading = true;
      getDuesTransactionHistory(this.queryData).then(res => {
        res.data.code ? (this.historyList = res.data.data) : "";
        this.total = this.historyList.length;
        this.loading = false;
      });
    }
  }
};
</script>
