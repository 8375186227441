<template>
  <div>
    <v-sheet>
      <v-data-table
        :headers="tableHeaders"
        :items="duesList"
        :loading="loading"
        sort-by="created_at"
        sort-desc
        class="elevation-0"
      >
        <template v-slot:[`item.nominal`]="{ item }">
          Rp. {{ item.nominal | priceFormat }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
        <template v-slot:[`item.billing_date`]="{ item }">
          {{ item.billing_date | dateFormat }}
        </template>
        <template v-slot:[`item.unpaid`]="{ item }">
          Rp.
          {{
            Number(item.paid) === 0
              ? 0
              : (Number(item.nominal) - Number(item.paid)) | priceFormat
          }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-btn
            v-if="Number(item.nominal) - Number(item.paid) == 0"
            depressed
            small
            block
            color="success"
          >
            {{ $t("dues.paid_off") }}
          </v-btn>
          <v-btn
            v-else
            small
            block
            color="warning"
            @click="openBillHistory(item)"
          >
            {{ $t("dues.unpaid") }}
          </v-btn>
        </template>
        <template v-slot:[`item.margin_date`]="{ item }">
          {{ item.margin_date | dateFormat }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="Number(item.nominal) - Number(item.paid) == 0"
                class="mr-2"
                fab
                x-small
                depressed
                color="success"
                @click="openPayForm(item)"
              >
                <v-icon small>mdi-wallet</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("dues.pay") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-sheet>

    <v-dialog v-model="historyDialog" width="800">
      <v-card>
        <v-card-title>
          History
          <v-spacer></v-spacer>
          <v-icon @click="historyDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <div v-if="historyList.length === 0">
            {{ $i18n.t("dues.no_transaction") }}
          </div>
          <v-data-table
            v-else
            :headers="tableHeadersHistory"
            :items="historyList"
            :loading="loadingHistory"
            sort-by="created_at"
            sort-desc
            class="elevation-0"
          >
            <template v-slot:[`item.nominal`]="{ item }">
              Rp. {{ item.nominal | priceFormat }}
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | dateFormat }}
            </template>
            <template v-slot:[`item.reference`]="{ item }">
              {{ item.reference ? item.reference : "-" }}
            </template>
            <template v-slot:[`item.margin_date`]="{ item }">
              {{ item.margin_date | dateFormat }}
            </template>
            <template v-slot:[`item.proof`]="{ item }">
              <v-img
                :src="item.proof"
                width="50"
                @click="showProof(item.proof)"
              ></v-img>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewProof" width="400">
      <v-card class="text-center">
        <v-img :src="selectedProof"></v-img>
        <v-btn class="ma-4" text @click="previewProof = false">{{
          $i18n.t("app.close")
        }}</v-btn>
      </v-card>
    </v-dialog>

    <DuesDetailAddTransaction
      :dialog="dialog"
      :detailDues="detailDues"
      :close="param => closeDialogTransaction(param)"
    />
  </div>
</template>

<script>
import moment from "moment";
moment.locale("id");

import i18n from "@/i18n";
import { mapGetters } from "vuex";
import {
  getDuesTransactionDetail,
  getDuesTransactionHistory
} from "@/api/admin/transactions";
import { priceFormat } from "@/utils/validate";

import DuesDetailAddTransaction from "./DuesDetailAddTransaction.vue";

export default {
  props: {
    tab: Number
  },
  components: {
    DuesDetailAddTransaction
  },
  filters: {
    priceFormat: value => {
      if (!value) return "0";
      return priceFormat(value);
    },
    dateFormat: value => {
      if (!value) return "0";
      return moment(value).format("DD MMM YYYY");
    }
  },
  computed: {
    ...mapGetters(["g_darkmode", "g_user"])
  },
  data() {
    return {
      dialog: false,
      total: 0,
      length: 0,
      loading: false,
      duesList: [],
      queryData: {
        person: this.$route.params.id
      },
      loadingHistory: false,
      previewProof: false,
      selectedProof: false,
      historyList: [],
      historyDialog: false,
      detailDues: null,
      tableHeadersHistory: [
        { text: i18n.t("dues.table.dues_type"), value: "name" },
        { text: i18n.t("dues.table.paid"), value: "nominal" },
        {
          text: i18n.t("dues.table.payment_date"),
          value: "created_at"
        },
        {
          text: i18n.t("dues.table.margin_date"),
          value: "margin_date"
        },
        {
          text: i18n.t("dues.table.reference"),
          value: "reference"
        },
        {
          text: i18n.t("dues.table.proof"),
          value: "proof"
        }
      ],
      tableHeaders: [
        { text: i18n.t("dues.table.dues_type"), value: "name" },
        { text: i18n.t("dues.table.total"), value: "nominal" },
        {
          text: i18n.t("dues.table.pay_date"),
          value: "billing_date"
        },
        {
          text: i18n.t("dues.table.margin_date"),
          value: "margin_date"
        },
        { text: i18n.t("dues.table.unpaid"), value: "unpaid" },
        {
          text: i18n.t("dues.table.margin_date"),
          value: "margin_date"
        },
        {
          text: "Status",
          value: "status",
          sortable: false
        },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 80
        }
      ]
    };
  },
  watch: {
    tab(newVal) {
      if (newVal === 0) {
        this.getDuesTransaction();
      }
    }
  },
  mounted() {
    this.getDuesTransaction();
  },
  methods: {
    showProof(proof) {
      this.selectedProof = proof;
      this.previewProof = true;
    },
    openBillHistory(item) {
      this.loadingHistory = true;
      getDuesTransactionHistory({
        person: this.$route.params.id,
        id: item.id
      })
        .then(res => {
          if (res.data.code) {
            this.historyList = res.data.data;
          }
          this.historyDialog = true;
          this.loadingHistory = false;
        })
        .catch(() => (this.loadingHistory = true));
    },
    closeDialogTransaction(param) {
      if (param === "save") {
        this.getDuesTransaction();
      }
      this.dialog = false;
    },
    getDuesTransaction() {
      this.loading = true;
      getDuesTransactionDetail(this.queryData).then(res => {
        res.data.code ? (this.duesList = res.data.data) : "";
        this.total = this.duesList.length;
        this.loading = false;
      });
    },
    openPayForm(item) {
      this.detailDues = null;
      setTimeout(() => {
        this.detailDues = item;
        this.dialog = true;
      }, 200);
    }
  }
};
</script>
