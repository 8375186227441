<template>
  <div v-if="detailDues">
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      width="400"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t("dues.pay_dues") }}
          <span v-if="detailDues.installments" class="ml-2"
            >- {{ detailDues.number_of_payment }}</span
          >
          <v-spacer></v-spacer>
          <v-icon @click="closeHandler(null)">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="6">
              <div>
                {{ $t("dues.bill_amout") }}
              </div>
              <div>Rp. {{ Number(detailDues.nominal) | priceFormat }}</div>
            </v-col>
            <v-col cols="6">
              <div>{{ $t("dues.unpaid") }}</div>
              <div>
                Rp.
                {{
                  (Number(detailDues.nominal) - Number(detailDues.paid))
                    | priceFormat
                }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-form v-model="valid" ref="formTransaction" lazy-validation>
            <v-row>
              <v-col cols="12">
                <div>{{ $t("dues.pay_nominal") }}</div>
                <div>
                  <v-text-field
                    v-model="form.nominal"
                    :rules="[v => !!v || $t('app.required')]"
                    :placeholder="String(must_paid)"
                    :disabled="!detailDues.installments"
                    type="number"
                    outlined
                    dense
                    @blur="validateNominal"
                  ></v-text-field>
                  <!-- @change="checkNominal()" -->
                </div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.reference"
                  :placeholder="$t('dues.table.reference')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="file"
                  :placeholder="$t('dues.upload_proff')"
                  prepend-inner-icon="mdi-upload"
                  prepend-icon=""
                  dense
                  hide-details
                  outlined
                  truncate-length="15"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
          <v-divider class="my-4"></v-divider>
          <v-row class="mt-2">
            <v-col cols="12" class="d-flex justify-space-between">
              <v-btn depressed @click="closeHandler(null)">{{
                $t("dues.cancel")
              }}</v-btn>
              <v-btn depressed color="primary" @click="saveTransaction">{{
                $t("dues.pay")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment";
import { payDuesTransaction } from "@/api/admin/transactions";
import { priceFormat } from "@/utils/validate";
import { uploadFile } from "@/api/admin/admin";

export default {
  props: {
    dialog: Boolean,
    close: Function,
    detailDues: Object
  },
  filters: {
    priceFormat: value => {
      if (!value) return "-";
      return priceFormat(value);
    },
    dateFormat: value => {
      if (!value) return "0";
      return moment(value).format("DD MMM YYYY");
    }
  },
  data() {
    return {
      valid: false,
      currentDate: moment().format("YYYY-MM-DD"),
      loadingSave: false,
      file: null,
      form: {
        nominal: null,
        reference: null,
        proof: null
      },
      must_paid: 0
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        const due = this.detailDues;

        this.must_paid = Number(due.nominal) - Number(due.paid);
        if (!due.installments) {
          this.form.nominal = this.must_paid;
        } else {
          this.form.nominal = null;
        }
        this.form.person = due.person;
        this.form.id = due.id;
      }
    }
  },
  methods: {
    closeHandler(param) {
      this.close(param);
      this.form = {
        person: null,
        id: null,
        nominal: 0,
        reference: null,
        proof: null
      };
      this.file = null;
    },
    validateNominal() {
      if (this.form.nominal == 0) {
        this.form.nominal = null;
      } else if (this.form.nominal > this.must_paid) {
        this.form.nominal = this.must_paid;
      }
    },
    async saveTransaction() {
      if (this.$refs.formTransaction.validate()) {
        this.$store.commit("TOGGLE_FULL_LOADING");

        if (this.file) {
          let bodyImage = new FormData();
          bodyImage.set("path", "dues");
          bodyImage.set("module", "TRANSACTION");
          bodyImage.append("file", this.file);

          const file = await uploadFile(bodyImage);
          this.form.proof = file.data.data.path;
        }

        payDuesTransaction(this.form)
          .then(res => {
            if (res.data.code) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: i18n.t("app.success_save"),
                color: "success"
              });
              this.closeHandler("save");
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.$store.commit("TOGGLE_FULL_LOADING");
          })
          .catch(() => this.$store.commit("TOGGLE_FULL_LOADING"));
      }
    }
  }
};
</script>
