<template>
  <div class="pa-5">
    <div class="mb-2">
      <span
        class="d-flex"
        style="cursor: pointer; width: 100px"
        @click="
          $router.push({
            name: 'Dues'
          })
        "
      >
        <v-icon class="mr-2">mdi-arrow-left</v-icon>
        <h4>{{ $t("dues.dues") }}</h4>
      </span>
    </div>
    <div class="d-flex align-center">
      <v-tabs v-model="tab" center-active>
        <v-tab>
          <v-icon class="mr-2">mdi-credit-card-multiple</v-icon>
          {{ $t("dues.dues") }}
        </v-tab>
        <v-tab>
          <v-icon class="mr-2">mdi-history</v-icon>{{ $t("dues.history") }}
        </v-tab>
      </v-tabs>
      <v-spacer />
      <v-select
        v-if="tab === 1"
        v-model="dueFilter"
        :label="$t('dues.master_type')"
        :loading="loadingDue"
        :items="dueList"
        clearable
        item-text="label"
        item-value="key"
        class="select-200"
        hide-details
        outlined
        dense
      />
    </div>

    <v-tabs-items v-model="tab" touchless class="mt-4">
      <v-tab-item>
        <DuesDetailTransaction :tab="tab" />
      </v-tab-item>
      <v-tab-item>
        <DuesDetailHistory :tab="tab" :dueFilter="dueFilter" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DuesDetailTransaction from "./DuesDetailTransaction.vue";
import DuesDetailHistory from "./DuesDetailHistory.vue";
import { getDuesType } from "@/api/admin/transactions";

export default {
  name: "DuesDetail",
  components: {
    DuesDetailTransaction,
    DuesDetailHistory
  },
  data() {
    return {
      tab: 0,
      loadingDue: false,
      dueList: [],
      dueFilter: 0
    };
  },
  created() {
    this.getDuesFilter();
  },
  methods: {
    getDuesFilter() {
      this.loadingDue = true;
      getDuesType({ person: this.$route.params.id }).then(res => {
        const { data, code } = res.data;
        if (code === 1) this.dueList = data;
        this.loadingDue = false;
      });
    }
  }
};
</script>
